<div class="chat">
    <button class="toggle-btn btn rounded-0 rounded-top pt-2" (click)="toggleChatBox(true)">
        Chat with Elsie
    </button>
    <div *ngIf="isOpened$ | async" class="chat-box shadow">
        <div class="card rounded-0 border-0">
            <div class="chat-header card-header rounded-0 text-white py-3">
                <span class="" style="float:left;">Chat with Elsie</span>
                <span title="Cancel" (click)="toggleChatBox(false)" class="icon material-icons-outlined"
                    style="float:right">close</span>
            </div>
            <div class="card-body" style="min-height: 420px;">
                <div class="chat-history" style="height:420px; overflow: auto" #chatHistory>
                    <ul class="m-b-0" *ngFor="let message of messageHistory$ | async; index as i">
                        <ng-container *ngIf="message.role === 'user' || message.role === 'assistant'">
                            <li class="clearfix">
                                <div class="message-data"
                                    [style.text-align]="message.role === 'user' ? 'right' : 'left'">
                                    <span class="message-data-time">{{ message.timestamp.toLocaleTimeString() }}</span>
                                </div>
                                <div class="message" *ngIf="message.type ==='text'" [class.my-message]="message.role === 'user'"
                                    [class.other-message]="message.role !== 'user'"
                                    [innerHTML]="message.content | safe: 'html'"></div>
                                <div *ngIf="message.type === 'chart'" class="other-message">
                                    <div><canvas id={{message.canvas}} style="width:100%;"></canvas>                                    </div>
                                </div>
                                <ng-container *ngIf="message.datasource">
                                    <small class="message-data-source">
                                        Source: {{ message.datasource }}
                                    </small>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="card-footer p-0 shadow bg-white">
                <div class="chat-form input-group shadow">
                    <input type="text" class="form-control border-0" [(ngModel)]="userMessage"
                        placeholder="Type your message" aria-label="Recipient's username"
                        aria-describedby="basic-addon2" [disabled]="isBusy$ | async" (keyup.enter)="submitQuestion()">
                    <div class="input-group-append my-2">
                        <button class="send-message-btn btn" type="button" [disabled]="!userMessage"
                            (click)="submitQuestion()">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>