<sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>

<div *ngIf="project" class="content">
    <div class="header">
        <div class="back" (click)="back()"><span class="arrow">◄</span><span class="highlight">{{ callbackLabel }}</span></div>
        <h3 [title]="'ID: ' + project.id">{{ project.name }}</h3>

        <div *ngIf="!isEditing && canEdit" class="action">
            <div title="Edit" class="icon material-icons-outlined cursor" (click)="toggleEditMode()">edit</div>
        </div>
        <div *ngIf="isEditing" class="action">
            <div title="Save" class="icon material-icons-outlined cursor done" (click)="save()">done</div>
            <div title="Cancel" class="icon material-icons-outlined cursor close" (click)="toggleEditMode()">close</div>
        </div>
    </div>

    <div class="data">
        <tabs [tabs]="tabs" 
            [activeTab]="activeTab" 
            [share]="true" 
            [urlParams]="urlParams"
            [beforeTabChange]="confirmTabChange.bind(this)"
            (tabChanged)="onTabChanged($event)"
            (messageEvent)="showMessage($event)"></tabs>
    </div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>