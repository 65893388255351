import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICvxProjectDeveloper } from '~/models/shared/cvxproject';
import { ProjectDeveloperAccount } from '~/models/shared/project-developer-account';
import { ProjectDeveloperService } from '~/services/shared/projects/project-developer-service';
import { AppComponent } from '~/app.component';

@Component({
    selector: 'developer-search',
    templateUrl: './developer-search.component.html',
    styleUrls: ['./developer-search.component.scss']
})

export class DeveloperSearchComponent implements OnInit {
    
    @Input() currentDeveloper: ICvxProjectDeveloper | undefined = undefined;
    @Output() developerSelectedEvent: EventEmitter<ICvxProjectDeveloper> = new EventEmitter();

    public searchMode: boolean = false;

    public searchResults: ProjectDeveloperAccount[] = [];

    public selectedDeveloper: ProjectDeveloperAccount | undefined;

    public searchInitiated: boolean = false;
    public addDeveloperMode: boolean = false;

    public isLoading: boolean = false;

    public searchText: string = '';
    public newDeveloperName: string = '';

    constructor(
        private readonly app: AppComponent,
        private readonly developerService: ProjectDeveloperService
    ) {
    }

    ngOnInit(): void {
    }

    public setSearchText(event: any) {
        this.searchText = event;
    }

    public setNewDeveloperText(event: any) {
        this.newDeveloperName = event;
    }

    public async findDevelopers() {
        if (this.searchText && this.searchText !== '') {
            try {
                this.isLoading = true;
                const results = await this.developerService.searchDevelopers(this.searchText);
                this.searchResults = results;
                this.searchInitiated = true;
            } catch (e) {

            } finally {
                this.isLoading = false;
                this.addDeveloperMode = false;
            }
        }
    }

    public setDeveloper(developer: ProjectDeveloperAccount) {
        this.selectedDeveloper = developer;
    }

    public showModal() {
        this.searchMode = true;
    }

    public select() {
        if (this.selectedDeveloper) {
            this.currentDeveloper = {
                id: this.selectedDeveloper.internalId ? this.selectedDeveloper.internalId : 0,
                name: this.selectedDeveloper.name,
                salesforceAccountId: this.selectedDeveloper.externalId ? this.selectedDeveloper.externalId : ''
            };

            this.developerSelectedEvent.emit(this.currentDeveloper);

            this.reset();
        }
    }

    public close() {
        this.reset();
    }

    public getSourceIcon(source: string): string {
        switch (source) {
            case 'Projects':
                return 'forest'

            case 'Metaregistry':
                return 'public'

            case 'Salesforce':
                return 'cloud'
        }

        return '';
    }

    public addDeveloperClick() {
        this.addDeveloperMode = true;
    }

    public cancelAddDeveloperClick() {
        this.addDeveloperMode = false;
    }

    public async saveDeveloperClick() {
        if (this.newDeveloperName && this.newDeveloperName.length > 0) {
            try {
                const newAccount = {
                    internalId: 0,
                    name: this.newDeveloperName,
                    externalId: undefined,
                    createDate: undefined,
                    sources: []
                }

                const createdAccount = await this.developerService.createDeveloper(newAccount);

                this.selectedDeveloper = createdAccount;
                this.select();
            } catch (error: any) {
                this.app.alertTitle = "Error Creating Developer";
                this.app.alertMessage = error.error.detail ? error.error.detail : error.message;
                this.app.showAlert = true;
            }
        }
    }

    private reset() {
        this.searchText = '';
        this.newDeveloperName = '';
        this.searchResults = [];
        this.selectedDeveloper = undefined;
        this.searchInitiated = false;
        this.searchMode = false;
        this.addDeveloperMode = false;
    }
}