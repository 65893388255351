import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { HttpService } from "~/services/core/http.service";
import { MessageThread, ChatResponse } from "~/services/shared/chat/chat-service.model";

@Injectable()
export class ChatService {
    constructor(
        private readonly httpService: HttpService
    ) {
        // Intentionally blank
    }

    submitChatMessage(messageThread: MessageThread): Observable<ChatResponse> {
        const requestUri = '/chat/messages';
        const task = this.httpService.Post(requestUri, messageThread);
        return from(task);
    }
}
