import { 
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { 
    BehaviorSubject, 
    Observable, 
    filter, 
    map, 
    forkJoin, 
    take 
} from 'rxjs';
import { 
    CreditInventory, 
    CreditInventoryAccount, 
    CreditInventoryCreateInfo, 
    CreditInventoryUpdateRequest, 
    CreditTransferRequest, 
    RetireCreditsRequest
} from '~/services/shared/credit-inventory/models/credit-inventory.model';
import {
    CreditInventoryAccountViewModel,
    CreditInventoryViewModel
} from '~/pages/projects/project-detail/credits/models/credits-inventory.model';
import { CreditInventoryService } from '~/services/shared/credit-inventory/credit-inventory.service';
import { Observe } from '~/classes/shared/observe.decorator';
import { CreditTransferDto, RetireCreditsDto } from './credit-transfer-form/credit-transfer-form.model';
import { CreditsTransferEvent } from './credit-issuance-table/credit-issuance-table.component';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';

@Component({
    selector: 'project-detail-credits',
    templateUrl: './project-detail-credits.component.html',
    styleUrls: ['./project-detail-credits.component.scss']
})
export class ProjectDetailCreditsComponent implements AfterViewInit {
    @Input() project: any;
    @Observe("project") project$!: Observable<any>;

    @Input() isEdit = false;
    @Observe('isEdit') isEdit$!: Observable<boolean>;

    @Input() saveData: boolean = false;
    @Observe('saveData') saveData$!: Observable<boolean>;

    @Output() saveError = new EventEmitter<any>();
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    private _isLoadingSubject = new BehaviorSubject<boolean>(false);
    isLoading$ = this._isLoadingSubject.asObservable();

    private _creditInventoriesChangeTracking = new Set<CreditInventoryViewModel>();
    private _creditInventoriesSubject = new BehaviorSubject<CreditInventoryViewModel[]>([]);
    creditInventories$ = this._creditInventoriesSubject.asObservable();

    public isLoading: boolean = false;
    public projectEdit: any;
    public canEdit: boolean = false;

    constructor(
        private readonly _creditInventoryService: CreditInventoryService,
        private readonly _userPermissionService: UserPermissionsService
    ) {
        // Intentionally blank
    }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnInit() {
        this.canEdit = await this._userPermissionService.canUpdateProjects();
        this.loadData();
    }

    public ngAfterViewInit(): void {
        this.isEdit$.pipe(
            filter((value) => value)
        ).subscribe({
            next: () => {
                this.projectEdit = { ...this.project };
                this.changeEvent.emit(this.projectEdit);
            }
        });
        this.saveData$.pipe(
            filter((saveData) => saveData)
        ).subscribe({
            next: () => {
                this._isLoadingSubject.next(true);
                    this.saveDataInternal().then(() => {
                        this.saveDataEvent.emit(false);
                        this.loadData();
                    }).catch((err) => {
                        console.error("Encountered an error whhile saving data...");
                        console.error(err);
                        this.saveError.emit();
                    }).finally(() => {
                        this._isLoadingSubject.next(false);
                    });
            }
        });
    }

    public onIssuanceTableRowChanged(data: CreditInventoryViewModel) {
        this._creditInventoriesChangeTracking.add(data);
    }

    public onIssuanceTableRowAdded(data: CreditInventoryViewModel) {
        this._creditInventoriesChangeTracking.add(data);
    }

    public onIssuanceTableUndoRowAdded(data: CreditInventoryViewModel) {
        const changed = Array.from(this._creditInventoriesChangeTracking)
            .filter(item => item != data);
        this._creditInventoriesChangeTracking = new Set(changed);
    }

    public transferCredits(event: CreditsTransferEvent) {
        const request = event.data as CreditTransferDto as CreditTransferRequest;
        this._creditInventoryService.transferCreditsAsync(request)
            .pipe(take(1))
            .subscribe({
                next: event.callback
            });
    }

    public retireCredits(event: CreditsTransferEvent) {
        const request = event.data as RetireCreditsDto as RetireCreditsRequest;
        this._creditInventoryService.retireCreditsAsync(request)
            .pipe(take(1))
            .subscribe({
                next: event.callback
            });
    }

    public async loadData() {
        this._isLoadingSubject.next(true);
        this._creditInventoryService.getCreditInventories(this.project.id).pipe(
            take(1),
            map((value) => {
                return value.map((creditInventory) => this.mapToCreditInventoryViewModel(creditInventory));
            })
        ).subscribe({
            next: (result) => {
                this._creditInventoriesSubject.next(result);
            },
            complete: () => {
                this._isLoadingSubject.next(false);
            }
        });
    }

    private saveDataInternal() {
        return new Promise<void>((resolve, reject) => {
            const tasks: Observable<any>[] = [];
            this.validateChanges();

            const invalids = Array.from(this._creditInventoriesChangeTracking)
                .filter(item => Object.keys(item.errors).length > 0);
            
            if (invalids.length > 0) {
                return reject(invalids);
            }

            this._creditInventoriesChangeTracking.forEach(creditInventory => {
                tasks.push(this.saveCreditInventory(creditInventory));
            });
            forkJoin(tasks).pipe(take(1)).subscribe({
                complete: () => {
                    this._creditInventoriesChangeTracking.clear();
                    this._isLoadingSubject.next(false);
                    resolve();
                },
                error: reject
            });
        })
    }
    private validateChanges() {
        this._creditInventoriesChangeTracking
            .forEach(item  => this.validateCreditInventory(item));
    }

    private validateCreditInventory(data: CreditInventoryViewModel) {
        data.errors = {};
        if (!data.serialNumber) {
            data.errors['serialNumber'] = "Value cannot be null or empty.";
        }
        if (!data.dateIssued) {
            data.errors['dateIssued'] = "Issuance date cannot be null or empty.";
        }
        if (!data.vintage) {
            data.errors['vintage'] = "Vintage must be greater than 0.";
        }
        if (data.creditsIssued <= 0) {
            data.errors['creditsIssued'] = "Credits issued must be greater than 0.";            
        }
        if (data.unitPrice != null && data.unitPrice <= 0) {
            data.errors['unitPrice'] = "Unit price must be greater than 0.";            
        }
    }

    private saveCreditInventory(data: CreditInventoryViewModel) {
        return data.id > 0 ?
            this.updateCreditInventory(data)
            : this.addCreditInventory(data);
    }

    private updateCreditInventory(data: CreditInventoryViewModel): Observable<boolean> {
        const updateRequest = {} as CreditInventoryUpdateRequest;
        const original = data['__data'] as CreditInventory;
        if (original.creditsIssued != data.creditsIssued) {
            updateRequest.creditsIssued = data.creditsIssued;
        }
        if (original.dateIssued != data.dateIssued) {
            updateRequest.dateIssued = data.dateIssued;
        }
        if (original.serialNumber != data.serialNumber) {
            updateRequest.serialNumber = data.serialNumber;
        }
        if (original.vintage != data.vintage) {
            updateRequest.vintage = data.vintage;
        }
        if (original.unitPrice != data.unitPrice) {
            updateRequest.unitPrice = data.unitPrice;
        }
        if (original.notes != data.notes) {
            updateRequest.notes = data.notes;
        }
        return this._creditInventoryService.updateCreditInventory(
            data.id, updateRequest);
    }

    private addCreditInventory(data: CreditInventoryViewModel): Observable<CreditInventory> {
        const createInfo = {
            creditsIssued: data.creditsIssued,
            dateIssued: data.dateIssued,
            serialNumber: data.serialNumber,
            unitPrice: data.unitPrice,
            vintage: data.vintage,
            notes: data.notes
        } as CreditInventoryCreateInfo;
        return this._creditInventoryService.addCreditInventory(this.project.id, createInfo)
    }

    private mapToCreditInventoryViewModel(creditInventory: CreditInventory): CreditInventoryViewModel {
        const value = Object.assign({}, {
            id: creditInventory.id,
            projectId: creditInventory.projectId,
            serialNumber: creditInventory.serialNumber,
            dateIssued: creditInventory.dateIssued,
            creditsIssued: creditInventory.creditsIssued,
            creditsAvailable: creditInventory.creditsAvailable,
            vintage: creditInventory.vintage,
            eligibilityTags: creditInventory.eligibilityTags,
            status: creditInventory.status,
            unitPrice: creditInventory.unitPrice,
            notes: creditInventory.notes,
            accounts: creditInventory.accounts?.map((account) => {
                return this.mapToCreditInventoryAccountViewModel(account);
            })
        }) as CreditInventoryViewModel;
        value['__data'] = creditInventory;
        return value;
    }

    private mapToCreditInventoryAccountViewModel(account: CreditInventoryAccount): CreditInventoryAccountViewModel {
        return {
            name: account.name,
            description: account.description,
            balance: account.balance
        } as CreditInventoryAccountViewModel
    }

    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;
        this.changeEvent.emit(this.projectEdit);
    }

    public eligilibityChange(event: any): void{
        this.infoChange({ field: "isArticle6Eligible", value: event.target.checked });
    }
}
