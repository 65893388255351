<div class="content">
    <div class="data">
        <div>
            <div class="wsjf-score-card">
                <div class="main">
                    <ccms-wsjf-score-card-chart 
                        [dataContext]="wsjfSummary$ | async"></ccms-wsjf-score-card-chart>
                    <div>
                        <table class="table-x">
                            <caption style="display: none;">WSJF</caption>
                            <tbody>
                                <tr id="businessValue">
                                    <th scope="col">
                                        Business Value:
                                        <span class="material-icons-outlined info-icon"
                                            [title]="businessValueTitle">info</span>
                                    </th>
                                    <td>
                                        <div class="d-flex ">

                                            <div class="table-responsive wsjf-values">
                                                <div class="row wsjf-header">
                                                    <div class="col"></div>
                                                    <div class="col ">IIR (No POD)</div>
                                                    <div class="col">NPV 10/t (POD)</div>
                                                    <div class="col ">VCM $/t cost (Secondary)</div>
                                                    <div class="col ">ACCU $/t cost (Secondary)</div>
                                                    <div class="col ">CA $/t cost (Secondary)</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col wsjf-cell">2</div>
                                                    <div class="col wsjf-cell">&lt;5%</div>
                                                    <div class="col wsjf-cell">&lt;$2</div>
                                                    <div class="col wsjf-cell">&gt;$20/t</div>
                                                    <div class="col wsjf-cell">&gt;$40/t</div>
                                                    <div class="col wsjf-cell">&gt;$30</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col wsjf-cell">4</div>
                                                    <div class="col wsjf-cell">5-7%</div>
                                                    <div class="col wsjf-cell">-$2-0</div>
                                                    <div class="col wsjf-cell">$16-20/t</div>
                                                    <div class="col wsjf-cell">$33-40/t</div>
                                                    <div class="col wsjf-cell">$26-30/t</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col wsjf-cell">6</div>
                                                    <div class="col wsjf-cell">8-10%</div>
                                                    <div class="col wsjf-cell">$>0-3</div>
                                                    <div class="col wsjf-cell">$11-15/t</div>
                                                    <div class="col wsjf-cell">$25-32/t</div>
                                                    <div class="col wsjf-cell">$21-25/t</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col wsjf-cell">8</div>
                                                    <div class="col wsjf-cell">11-13%</div>
                                                    <div class="col wsjf-cell">$>3-6</div>
                                                    <div class="col wsjf-cell">$5-10/t</div>
                                                    <div class="col wsjf-cell">$17-24/t</div>
                                                    <div class="col wsjf-cell">$13-20/t</div>
                                                </div>
                                                <div class="row">
                                                    <div class="col wsjf-cell">10</div>
                                                    <div class="col wsjf-cell">>13%</div>
                                                    <div class="col wsjf-cell">>$6</div>
                                                    <div class="col wsjf-cell">&lt;$5/t</div>
                                                    <div class="col wsjf-cell">&lt;$16/t</div>
                                                    <div class="col wsjf-cell">&lt;$13/t</div>
                                                </div>
                                            </div>



                                        </div>
                                    </td>
                                </tr>
                                <tr id="timingSensitivity">
                                    <th scope="col">Time Criticality: <span class="material-icons-outlined info-icon"
                                            [title]="timingSensitivityTitle">info</span>
                                    </th>
                                    <td>
                                        <div class="d-flex">
                                            <div style="float: left;min-width: 150px;">Closing Urgency:</div>

                                            <ul style="list-style-type: none;  float: right; ">
                                                <li>1 = Greater than a year</li>
                                                <li>2 = Within a year</li>
                                                <li>4 = Within 2 quarters</li>
                                                <li>6 = Within the quarter</li>
                                                <li>8 = Within the month</li>
                                                <li>10 = As soon as possible</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr id="opportunityRisk">
                                    <th scope="col">RROE:<span class="material-icons-outlined info-icon"
                                            [title]="opportunityRiskTitle">info</span>
                                    </th>
                                    <td>
                                        <div class="d-flex">
                                            <div style="float: left;max-width: 150px;">
                                                Opportunity & Risk Reduction:
                                            </div>

                                            <ul style="list-style-type: none;  float: right; ">
                                                <li>1 = Small w/ no Additional Opportunity / High Risk</li>
                                                <li>2 = Low</li>
                                                <li>4 = Some</li>
                                                <li>6 = Moderate</li>
                                                <li>8 = High</li>
                                                <li>10 = Major or Enables Major Opportunity</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                <tr id="effort">
                                    <th scope="col">Estimated Effort:<span class="material-icons-outlined info-icon"
                                            [title]="effortTitle">info</span>
                                    </th>
                                    <td>
                                        <div class="d-flex">
                                            <div style="float: left;max-width: 150px;">Complexity & Resources
                                                Required:
                                            </div>
                                            <ul style="list-style-type: none;  float: right">
                                                <li>1 = Lt Offtake</li>
                                                <li>4 = Sponsorship</li>
                                                <li>6 = Asset Ownership</li>
                                                <li>8 = Platform - Downgraded for Complex Geography (Add 2 - 5
                                                    points)</li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="error">
                <ccms-alert [type]="AT.WARNING" 
                    [message]="error.errors" class="alert-container"></ccms-alert>
            </ng-container>
        </div>
    </div>
    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" (changeEvent)="infoChange($event)"></tab-info>

        <br />

        <ccms-wsjf-score-card-form #scoreCardForm 
            [form]="form$ | async" [isEdit]="isEdit"
            [portfolioId]="project?.projectPortfolioId" 
            (save)="saveWsjfScoreCard($event)"></ccms-wsjf-score-card-form>
        <ng-container *ngIf="error">
            <ccms-alert [type]="AT.WARNING" [message]="error.errors" class="alert-container"></ccms-alert>
        </ng-container>
    </div>
</div>