<div class="content">
    <div class="data">
        <div class="options">
            <div title="Graph" class="icon material-icons" [ngClass]="{ active: type === '' }"(click)="type = ''; typeName = 'Graph'; loadData({ chart: true });">insert_chart</div>
            <div title="Issuances" class="icon material-icons" [ngClass]="{ active: type === 'Issued' }" (click)="type = 'Issued'; typeName = 'Issuance'; loadData();">assignment_add</div>
            <div title="Assignments" class="icon material-icons" [ngClass]="{ active: type === 'Assigned' }" (click)="type = 'Assigned'; typeName = 'Assignments'; loadData();">assignment_turned_in</div>
            <div title="Retirements" class="icon material-icons" [ngClass]="{ active: type === 'Retired' }" (click)="type = 'Retired'; typeName = 'Retirements'; loadData();">content_paste_go</div>
        </div>
        <div *ngIf="type === ''" class="graph">
            <metaregistry-detail-credits-graph [data]="fullData" [project]="this.project"></metaregistry-detail-credits-graph>

            <sdk-loading [isLoading]="isLoading"></sdk-loading>
        </div>
        <div *ngIf="type !== ''" class="grid">
            <sdk-datagrid
                [title]="typeName"
                [uniqueIdentifier]="uniqueIdentifier"
                titleStyle="text-align: left; font-size: 1.3em; font-weight: normal; color: var(--blue-dark); font-family: 'GothamBold';"
                [datasets]="datasets"
                [name]="activeView"
                [data]="data"
                [fullData]="fullData"
                [forceReload]="forceReload"
                [columns]="columns"
                [options]="gridOptions"
                [dbPage]="dbPage"
                [dbTotal]="dbTotal"
                [isDataWrapped]=false
                [minimizeOptions]=true
                [isLoading]="isLoading"
                [error]="error"
                [settings]="allSettings"
                (loadDataEvent)="loadData($event)"
                (settingsSavedEvent)="saveSettings($event)">
            </sdk-datagrid>
        </div>

        <ng-template #eligibilityTemplate let-rowItem="rowItem">
          <div (click)="$event.stopPropagation()">
              <div>
                  {{ rowItem.eligibility ? rowItem.eligibility.join(', ') : '' }}
              </div>
          </div>
        </ng-template>
    </div>
    <div class="info">
        <table class="table-x">
            <caption style="display: none;">Details</caption>
            <tbody>
                <tr>
                    <th scope="col">Project ID:</th>
                    <td [title]="project.id || '-'">{{ project.id || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Status:</th>
                    <td>{{ project.status || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Registry:</th>
                    <td><a [href]="project.registryUrl" target="_blank">{{ project.registry }}</a></td>
                </tr>
                <tr>
                    <th scope="col">Country:</th>
                    <td>{{ project.country || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">CCB Certified:</th>
                    <td>{{ project.ccbCertifications || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Program Eligibility:</th>
                    <td>{{ project.programEligibility || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Crediting Start:</th>
                    <td>{{ project.creditingPeriodStartDate | date: 'MM/dd/yyyy' || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Crediting End:</th>
                    <td>{{ project.creditingPeriodEndDate | date: 'MM/dd/yyyy' || '-' }}</td>
                </tr>
                <tr>
                    <th scope="col">Credits Issued:</th>
                    <td>{{ project.creditsIssued | number }}</td>
                </tr>
                <tr>
                    <th scope="col">Credits Retired:</th>
                    <td>{{ project.creditsRetired | number }}</td>
                </tr>
                <tr>
                    <th scope="col">Credits Remaining:</th>
                    <td>{{ project.creditsRemaining | number }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
