<div class="content">
    <div class="data">
        <ng-container *ngIf="isEdit">
            <ccms-icon-button class="small add-inventory-btn " icon="icon-expand-plus" (click)="onAdd()"
                aria-label="Add agreement">Row</ccms-icon-button>
        </ng-container>
        <div [class.edit]="isEdit" [ngStyle]="{'display': hasRecords ? 'block' : 'none'}" >
            <sdk-datagrid [style]="" fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial" [datasets]="datasets" [data]="data"
                [uniqueIdentifier]="'projects.project-detail.agreements.project-detail-agreements'" [columns]="columns" [options]="gridOptions" [showOptions]=true [showFooter]=false [detailTemplate]="detail"
                [isLoading]="isLoading" (loadDataEvent)="loadData($event)" #grid>
            </sdk-datagrid>
        </div>
      
    </div>
    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" (changeEvent)="infoChange($event)"></tab-info>
    </div>
</div>

<ng-template #paper let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <select name="paper" (change)="onPaperChange($event, rowItem);">
            <ng-container *ngFor="let paper of agreementPapers">
                <option [value]="paper | json" [selected]="paper.id === rowItem.paper?.id">
                    {{paper.description}}</option>
            </ng-container>
        </select>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{rowItem?.paper?.description}}
    </ng-container>
</ng-template>
<ng-template #type let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <select class="required" name="type" (change)="onTypeChange($event, rowItem);">
            <ng-container *ngFor="let type of agreementTypes">
                <option [value]="type | json" [selected]="type.id === rowItem.type?.id">
                    {{type.description}}</option>
            </ng-container>
        </select>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{ rowItem?.type?.description }}
    </ng-container>

</ng-template>
<ng-template #detail let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <form>
            <div class="row">
                <div class="col-3 labels">Source:</div>
                <div class="col-9 element">
                    <textarea type="text" name="source" id="source" required [(ngModel)]="rowItem.source" style="width: 100%" rows="2" 
                        class="required" [title]="rowItem.source" (ngModelChange)="onDataChange(rowItem)">                                     
                </div>
            </div>
            <div class="row">
                <div class="col-3 labels">Counter Party:</div>
                <div class="col-9 element">
                    <textarea name="counterParty" rows="3" style="width: 100%;" [(ngModel)]="rowItem.counterParty"
                        (ngModelChange)="onDataChange(rowItem)"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-3 labels">Restrictions:</div>
                <div class="col-9 element">
                    <textarea name="restrictions" [(ngModel)]="rowItem.restrictions" style="width: 100%;" rows="5"
                        (ngModelChange)="onDataChange(rowItem)"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-3 labels">Obligations:</div>
                <div class="col-9 element">
                    <textarea name="obligations" style="width: 100%;" rows="5" [(ngModel)]="rowItem.obligations"
                        (ngModelChange)="onDataChange(rowItem)"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-12 labels">Notes:</div>
            </div>
            <div class="row">
                <div class="col-12 element notes">
                    <textarea [(ngModel)]="rowItem.notes" rows="10" style="width: 100%;" name="notes"
                        (ngModelChange)="onDataChange(rowItem)"></textarea>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        <div class="row">
            <div class="col-3 labels">Source:</div>
            <div class="col-9 element">
                <a [href]="rowItem.source" target="_blank" [title]="rowItem.source">link</a>
            </div>
        </div>
        <div class="row">
            <div class="col-3 labels">Counter Party:</div>
            <div class="col-9 element">{{rowItem.counterParty}}</div>
        </div>
        <div class="row">
            <div class="col-3 labels">Restrictions:</div>
            <div class="col-9 element">{{rowItem.restrictions}}</div>
        </div>
        <div class="row">
            <div class="col-3 labels">Obligations:</div>
            <div class="col-9 element">{{rowItem.obligations}}</div>
        </div>
        <div class="row">
            <div class="col-12 labels">Notes:</div>
        </div>
        <div class="row">
            <div class="col-12 element notes">{{rowItem.notes}}</div>
        </div>
    </ng-container>
</ng-template>
<ng-template #name let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <input type="text" name="name" id="name" required [(ngModel)]="rowItem.name" class="required" (ngModelChange)="onDataChange(rowItem)" style="height: 30px; min-width: 300px; margin-right: 20px;">
    </ng-container>
    <ng-container *ngIf="!isEdit">
        <div style="overflow: hidden;">
            <a [href]="rowItem.source" target="_blank" [title]="rowItem.source">{{rowItem.name}}</a>
        </div>
    </ng-container>
</ng-template>
<ng-template #duration let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <input type="number" name="duration" [(ngModel)]="rowItem.duration" style="max-width: 50px;"
            (ngModelChange)="onDataChange(rowItem)">
    </ng-container>
    <ng-container *ngIf="!isEdit && rowItem.duration != null" >
        {{rowItem.duration}} yrs
    </ng-container>
</ng-template>
<ng-template #destructionRequested let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <div style="text-align: center;">
            <input type="checkbox" [(ngModel)]="rowItem.destructionRequested" name="destructionRequested"
                (ngModelChange)="onDataChange(rowItem)">
        </div>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{rowItem.destructionRequested}}
    </ng-container>
</ng-template>
<ng-template #destructionRequestedDate let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <sdk-textbox [value]="rowItem.destructionRequestedDate" validCharacters="calendar" pattern="MM/DD/YYYY"
            (changeCallBackEvent)="setDate($event, rowItem, 'destructionRequestedDate')"></sdk-textbox>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{rowItem.destructionRequestedDate | date}}
    </ng-container>
</ng-template>

<ng-template #dateExpires let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <sdk-textbox [value]="rowItem.dateExpires" validCharacters="calendar" pattern="MM/DD/YYYY"
            (changeCallBackEvent)="setDate($event, rowItem, 'dateExpires')"></sdk-textbox>
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{rowItem.dateExpires | date}}
    </ng-container>
</ng-template>
<ng-template #dateExecuted let-rowItem="rowItem">
    <ng-container *ngIf="isEdit">
        <sdk-textbox [value]="rowItem.dateExecuted" validCharacters="calendar" pattern="MM/DD/YYYY"
            (changeCallBackEvent)="setDate($event, rowItem, 'dateExecuted')"></sdk-textbox>       
    </ng-container>
    <ng-container *ngIf="!isEdit">
        {{rowItem.dateExecuted | date}}
    </ng-container>
</ng-template>

<ng-template #actionRight let-rowItem="rowItem" let-activeRow="activeRow" let-index="index">
    <div class="action">
        <div title="Delete" class="icon material-icons-outlined cursor delete"
            (click)="onDelete(rowItem.id);$event.stopPropagation();">delete
        </div>
    </div>
</ng-template>
