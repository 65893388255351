import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ICustomerProduct, IDidNotPursueReason, IInvestmentType, IMethodologyProtocol, ITechnicalReviewRecommendation, ITechnicalReviewStatus } from "~/models/shared/common";
import { ICvxProjectStatus, ICvxProjectType } from "~/models/shared/cvxproject";
import { ICountry, IRegion } from "~/models/shared/location";
import { CountryService } from "~/services/shared/country.service";
import { InvestmentService } from "~/services/shared/investment.service";
import { ProjectService } from "~/services/shared/projects/project.service";
import { UserInfo } from '~/models/shared/users/user-info';


@Component({
    selector: 'project-add-info',
    templateUrl: './project-add-info.component.html',
    styleUrls: ['./project-add-info.component.scss']
})

export class ProjectAddInfo implements OnInit {
    public isLoading = true;
    public productList: ICustomerProduct[] = [];
    public projectTypeList: ICvxProjectType[] = [];
    public investmentTypeList: IInvestmentType[] = [];
    public countryList: ICountry[] = [];
    public projectStatusList: ICvxProjectStatus[] = [];
    public projectStatusCategoryList: string[] = [];
    public methodologyList: IMethodologyProtocol[] = [];
    public technicalReviewStatusList: ITechnicalReviewStatus[] = [];
    public technicalReviewRecommendationList: ITechnicalReviewRecommendation[] = [];
    public doNotPursueList: IDidNotPursueReason[] = [];
    public regions: IRegion[] = [];
    public priorityList: number[] = Array.from({ length: 3 }, (_, index) => index + 1);
    public showStatusExt: boolean = false;
    public doNotPursueOtherReason: boolean = false;
    public globalLocation: any = {
        name: "Global",
        id: -1,
        alpha2Code: "",
        alpha3Code: "",
        regionCode: -1,
        regionName: "Global",
        latLongCoords: null,
        countryRisk: null,
        region: null,
        subRegion: null
    };

    @Input() project: any;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<{success: boolean, step: any}> = new EventEmitter();

    constructor(
        private projectService: ProjectService,
        private investmentService: InvestmentService,
        private countryService: CountryService) {

    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
       
    }
    private async loadData(): Promise<void> {
        try {
            await this.projectService.getProducts().then((data: any) => {
                this.productList = data;
            });
            await this.projectService.getTypes().then((data: any) => this.projectTypeList = data);
            await this.investmentService.getTypes().then((data: any) => this.investmentTypeList = data);
            await this.countryService.getCountries().then((data: any) => {
                this.countryList = data;
                if (!this.countryList.find(element => element.id == this.globalLocation.id)) {
                    this.countryList.unshift(this.globalLocation);
                }
                this.regions = this.countryList
                    .map(element => element.region)
                    .filter(element => element != null || element != undefined)
                    .filter((region, index, self) => self.findIndex(r => r.id === region.id) === index); // Filter unique regions  
            });
            await this.projectService.getStatuses().then((data: any) => {
                this.projectStatusList = data;
                this.projectStatusCategoryList = this.projectService.extractUniqueStatusCategories(this.projectStatusList);
                const backlog = this.projectStatusList.find(element => element.id == 13);
                this.setSelectModelValue('status', { target: { value: JSON.stringify(backlog) } });
                
            });
            await this.projectService.getMethodologies().then((data: any) => this.methodologyList = data);
            await this.projectService.getTechnicalReviewStatuses().then((data: any) => {
                this.technicalReviewStatusList = data;
            });
            await this.projectService.getTechnicalReviewRecommendations().then((data: any) => {
                this.technicalReviewRecommendationList = data;
            });
            await this.projectService.getDNPStatuses().then((data: any) => {
                this.doNotPursueList = data;
            });
            
        }
        catch (error: any) {
            this.productList = [];
            this.projectTypeList = [];
            this.investmentTypeList = [];
            this.countryList = [];
            this.regions = [];
            this.projectStatusList = [];
            this.methodologyList = [];
            this.technicalReviewStatusList = [];
            this.technicalReviewRecommendationList = [];
            this.doNotPursueList = [];
            console.error(error);
        }
        finally {
            this.isLoading = false;
        }
    }

    public didNotPursueReasonRequired = true;
    public didNotPursueReasonOtherRequired = true;
    public priorityRequired = true;
    public cvxProjectTypeRequired = true;
    public productRequired = true;
    public investmentTypeRequired = true;
    public countryRequired = true;
    public contactRequired = true;
    public developerRequired = true;
    public methodologyProtocolRequired = true;
    private updateRequiredFields(statusId: number) {
        const LEAD_STATUS_ID = 14;
        const isRequired = statusId !== LEAD_STATUS_ID;
        this.didNotPursueReasonRequired = isRequired; // string?
        this.didNotPursueReasonOtherRequired = isRequired; // string?
        this.priorityRequired = isRequired; // int?
        this.cvxProjectTypeRequired = isRequired; // int? (ref)
        this.productRequired = isRequired; // int? (ref)
        this.investmentTypeRequired = isRequired; // int? (ref)
        this.countryRequired = isRequired; // int? (ref)
        this.contactRequired = isRequired; // string?
        this.developerRequired = isRequired; // string?
        this.methodologyProtocolRequired = isRequired; // int? (ref)
    }

    public setCountry(event: any): void{
        this.project.location = this.project.location || {};
        if (event.target.value) {
            const selected = JSON.parse(event.target.value);
            
            if (selected.id == this.globalLocation.id) {
                this.project.isGlobalLocation = true;
                this.project.location.addressCountry = null;
                this.project.country = null;
            }
            else {
                this.project.country = selected;
                this.project.location.addressCountry = selected;
                this.project.isGlobalLocation = null;
            }
        }
        else {
            this.project.country = null;
            this.project.location.addressCountry = null;
            this.project.isGlobalLocation = null;
        }
        this.changeEvent.emit();
    }
   
    public onMethodologySelected(value: number) {
        this.project.methodologyId = value;
        this.changeEvent.emit();
    }

    public setSelectModelValue(field: string, event: any) {
        if (event.target.value) {
            const value = JSON.parse(event.target.value);
            
            if (field === 'status') {
                this.updateRequiredFields(value.id);
            }
            this.project[field] = value;    
            switch (field) {
                case 'status':
                    {
                        this.showStatusExt = this.project[field]?.id === 11;
                        break;
                    }
                case 'didNotPursueReason': {
                    this.doNotPursueOtherReason = this.project[field].id === 7;
                    break;
                }
                
            }
            this.changeEvent.emit();
        }
    }

    public setModelValue(field: string, value: any, allowNull: boolean = false) {
        if (field && (allowNull || value)) {
            this.project[field] = value;
            this.changeEvent.emit();
        }
    }

    public setReductionType(event: any) {
        this.project.isRemoval = false;
        this.project.isAvoidance = false;

        if (event.target.value !== "") {
            switch (event.target.value) {
                case "Removal":
                    this.project.isRemoval = true;
                    break;

                case "Avoidance":
                    this.project.isAvoidance = true;
                    break;

                case "Both":
                    this.project.isRemoval = true;
                    this.project.isAvoidance = true;
                    break;
            }
            this.changeEvent.emit();
        }
    }

    public setExecutionStartDate(value: any) {
        const date = new Date(value);
        this.project.executionStartDate = date;
        this.changeEvent.emit();
    }

    public setNdaExecutionDate(value: any) {
        const date = new Date(value);
        this.project.ndaExecutionDate = date;
        this.changeEvent.emit();
    }

    public setContact(event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const contact: UserInfo = event;
            id = contact.id;
            name = contact.displayName;
        }

        this.setModelValue('contactId', id, true);
        this.setModelValue('contact', name, true);
    }
   
}

