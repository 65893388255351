<div class="content">
    <div class="data">
        <div class="header">Internal</div>
        <div class="detail">
            <table class="table-x" aria-hidden="true">
                <caption style="display: none;">Internal</caption>
                <tbody>

                    <tr>
                        <th scope="col">Legal Lead</th>
                        <td>
                            <user-selection [entity]="entity" [field]="'legalLead'" (userSelectedEvent)="userSelectionChange('legalLead', $event)"></user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Originator</th>
                        <td>
                            <user-selection [entity]="entity" [field]="'originator'" (userSelectedEvent)="userSelectionChange('originator', $event)"></user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Term Sheet Lead</th>
                        <td>
                            <user-selection [entity]="entity" [field]="'termSheetLead'" (userSelectedEvent)="userSelectionChange('termSheetLead', $event)"></user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Negotiator</th>
                        <td>
                            <user-selection [entity]="entity" [field]="'negotiator'" (userSelectedEvent)="userSelectionChange('negotiator', $event)"></user-selection>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header">External</div>
        <div class="detail">
            <table  aria-hidden="true" class="table-x">
                <caption style="display: none;">External</caption>
                <tbody>

                    <tr>
                        <th scope="col">Operator</th>
                        <td><sdk-textbox  width="100%"
                                (changeCallBackEvent)="infoChange({ field: 'operator', value: $event })"></sdk-textbox>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Verifier</th>
                        <td><sdk-textbox  width="100%"
                                (changeCallBackEvent)="infoChange({ field: 'verifier', value: $event })"></sdk-textbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header">Ownership</div>
        <div class="detail">
            <div *ngIf="!owners || owners.length === 0" class="nodata">No Owners defined for this project.</div>
            <table *ngIf="owners && owners.length > 0" aria-hidden="true" class="table-x">
                <caption style="display: none;">Ownership</caption>
                <tbody>
                    <tr *ngFor="let owner of owners; let ndx = index">
                        <td style="width: 50%"><sdk-textbox [value]="owner.accountName"
                                (changeCallBackEvent)="ownerChange(ndx, 'accountName', $event)"></sdk-textbox></td>
                        <td style="width: 50%"><sdk-textbox validCharacters="decimal" pattern="1.0-2" width="50px"
                                style="display: inline-flex" [value]="owner.percentOwned"
                                (changeCallBackEvent)="ownerChange(ndx, 'percentOwned', $event)"></sdk-textbox> %</td>
                        <td>
                            <div title="Delete" class="icon material-icons-outlined cursor delete"
                                (click)="deleteOwner(owner.accountId)">delete</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div style="margin-top: 10px"><button class="button small" (click)="addOwner()">+ Owner</button></div>
        </div>
    </div>
</div>