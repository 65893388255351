<div class="content">
    <select (change)="userSelectionChange($event)">
        <option *ngIf="isLoading" value="">
            Loading ....
        </option>
        <option value=""
                [selected]="!selectedUserName || selectedUserName === ''">
            ...
        </option>
        <ng-container *ngFor="let user of userSelectionList">
            <option [value]="user.id"
                    [selected]="selectedUserName && selectedUserName === user.displayName">
                {{ user.displayName }}
            </option>
        </ng-container>
    </select>
</div>