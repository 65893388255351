import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppComponent } from '~/app.component';

import { Clone } from '~/classes/shared/clone';

import { ProjectService } from '~/services/shared/projects/project.service';
import { PriceDeckService } from '~/services/shared/price-deck.service';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { SaveDataEvent } from '../models/save-data-event.model';

@Component({
    selector: 'project-detail-screening',
    templateUrl: './project-detail-screening.component.html',
    styleUrls: ['./project-detail-screening.component.scss']
})

export class ProjectDetailScreeningComponent {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = false;
    public projectEdit: any;
    public screeningData: any;
    public screeningEdit: any;

    public returnMetricList: any = [];
    public priceDeckList: any = [];

    constructor(
        private app: AppComponent,
        private projectService: ProjectService,
        private priceDeckService: PriceDeckService,
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnInit() {
        await this.loadScreeningData();
        await this.loadPriceDeckList();
        this.loadReturnMetricList();
    }

    async ngOnChanges(_args: any) {
        if (this.saveData) {
            await this.save();
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = Clone.deepCopy(this.project);
            this.screeningEdit = Clone.deepCopy(this.screeningData);

            if (!this.screeningEdit) {
                this.screeningEdit = {
                    isAccretiveToPorfolio: null,
                    isScalable: null,
                    isActionable: null,
                    reviewPassed: null,
                    totalCreditsForecast: null,
                    reductionType: null,
                    registryType: null,
                    hasCobenefits: null,
                    isFungible: null,
                    estimatedAbatementCost: null,
                    returnsMetric: null,
                    estimatedMidInternalRateOfReturn: null,
                    estimatedMinimumInternalRateOfReturn: null,
                    estimatedMaximumInternalRateOfReturn: null,
                    midNetPresentValuePerTonne: null,
                    minNetPresentValuePerTonne: null,
                    maxNetPresentValuePerTonne: null,
                    isAccretiveToPorfolioNotes: null,
                    isScalableNotes: null,
                    isActionableNotes: null,
                    isReviewPassedNotes: null,
                    priceDeckId: null,
                    confidenceEstimate: null
                }
            }

            this.isLoading = false;
        }
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public getReturnMetric(value: any) {
        let name: any = null;

        if (value) {
            if (value.id === 1) {
                name = "IRR"
            }
            if (value.id === 2) {
                name = "NPV"
            }
        }

        return name;
    }

    public getPriceDeck(id: any) {
        let name: any = null;

        if (id !== null) {
            let ndx = this.priceDeckList.findIndex((p: any) => p.id === id);

            if (ndx > -1) {
                name = this.priceDeckList[ndx].name;
            }
        }

        return name;
    }

    public setNotes(field: any, event: any) {
        this.screeningEdit[field] = event.target.value;
    }

    public setReductionType(event: any) {
        this.screeningEdit.reductionType = event.target.value;
    }

    public setRegistryType(event: any) {
        this.screeningEdit.registryType = event.target.value;
    }

    public setReturnsMetric(event: any) {
        if (event.target.value === "") {
            this.screeningEdit.returnsMetric = null;
            this.screeningEdit.estimatedMidInternalRateOfReturn = null;
            this.screeningEdit.estimatedMinimumInternalRateOfReturn = null;
            this.screeningEdit.estimatedMaximumInternalRateOfReturn = null;
            this.screeningEdit.midNetPresentValuePerTonne = null;
            this.screeningEdit.minNetPresentValuePerTonne = null;
            this.screeningEdit.maxNetPresentValuePerTonne = null;
        } else {
            this.screeningEdit.returnsMetric = JSON.parse(event.target.value);
        }
    }

    public setPriceDeck(event: any) {
        if (event.target.value === "") {
            this.screeningEdit.priceDeck = {};
        } else {
            this.screeningEdit.priceDeckId = JSON.parse(event.target.value).id;
        }
    }

    public screeningChange(event: any) {
        switch (event.value) {
            case "true":
                this.screeningEdit[event.field] = true;
                break;

            case "false":
                this.screeningEdit[event.field] = false;
                break;

            default:
                if (Number(event.value) || event.value === "0") {
                    this.screeningEdit[event.field] = Number(event.value);
                } else {
                    this.screeningEdit[event.field] = event.value;
                }
                break;
        }
    }

    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;

        this.changeEvent.emit(this.projectEdit);
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private loadReturnMetricList() {
        this.returnMetricList = [];

        this.returnMetricList.push({ id: 1, name: 'IRR' });
        this.returnMetricList.push({ id: 2, name: 'NPV' });
    }

    private async loadPriceDeckList(): Promise<void> {
        this.priceDeckList = [];

        try {
            await this.priceDeckService.getValues().then((data: any) => {
                this.priceDeckList = data;
            });
        } catch (error: any) {
            this.priceDeckList = [];
        }
    }

    private async loadScreeningData(data?: any) {
        this.isLoading = true;

        try {
          if (!data) {
            await this.projectService.getScreening(this.project.id).then((data: any) => {
                this.screeningData = data;
            });
          } else {
            this.screeningData = data;
          }
        } catch (error: any) {
        }

        this.isLoading = false;
    }

    private async save() {
        this.isLoading = true;

        if (this.screeningEdit) {
            await this.projectService.saveScreening(this.project.id, this.screeningEdit).then(async () => {
                await this.loadScreeningData();
                this.saveDataEvent.emit(false);
            }, (error: any) => {
                const ccmsError = new CcmsServerError(error);
                this.app.alertTitle = "Error saving Screening Data";
                this.app.alertMessage = ccmsError.errors;
                this.app.showAlert = true;
                this.loadScreeningData(this.screeningEdit);
                this.saveDataEvent.emit(new SaveDataEvent(false, ccmsError.errors));
            });
        }
        
        this.isLoading = false;
    }
}
