<div class="content">
    <div class="data">
        <table class="table-x">
            <caption style="display: none;">Tech</caption>
            <tbody>
                <tr>
                    <th scope="col">CNE Technical Lead</th>
                    <td *ngIf="!isEdit">{{ project.technicalLead || '-' }}</td>
                    <td *ngIf="isEdit">
                        <user-selection [entity]="'CvxProject'"
                                        [field]="'technicalLead'"
                                        [selectedUserName]="projectEdit.technicalLead"
                                        (userSelectedEvent)="userSelectionChanged('technicalLead', $event)">
                        </user-selection>
                    </td>
                </tr>
                <tr>
                    <th scope="col">External Tech Resource</th>
                    <td *ngIf="!isEdit">{{ project.externalTechResource || '-' }}</td>
                    <td *ngIf="isEdit"><sdk-textbox [value]="projectEdit.externalTechResource" width="100%"
                            (changeCallBackEvent)="infoChange({ field: 'externalTechResource', value: $event })"></sdk-textbox></td>
                </tr>
                <tr>
                    <th scope="col">Status</th>
                    <td *ngIf="!isEdit">{{ project.technicalReviewStatus?.name || '-' }}</td>
                    <td *ngIf="isEdit">
                        <select (change)="setStatus($event)">
                            <option value="" [selected]="!projectEdit.technicalReviewStatus || projectEdit.technicalReviewStatus.name === ''">...</option>
            
                            <ng-container *ngFor="let status of statusList">
                                <option [value]="status | json" [selected]="projectEdit.technicalReviewStatus?.id === status.id">{{ status.name }}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Recommendation</th>
                    <td *ngIf="!isEdit">{{ project.technicalReviewRecommendation?.name || '-' }}</td>
                    <td *ngIf="isEdit">
                        <select (change)="setRecommendation($event)">
                            <option value="" [selected]="!projectEdit.technicalReviewRecommendation || projectEdit.technicalReviewRecommendation.name === ''">...</option>
            
                            <ng-container *ngFor="let recommendation of recommendationList">
                                <option [value]="recommendation | json" [selected]="projectEdit.technicalReviewRecommendation?.id === recommendation.id">{{ recommendation.name }}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Last Review Date</th>
                    <td *ngIf="!isEdit">{{ project.technicalReviewCompletionDate && project.technicalReviewCompletionDate.split('T')[0] | date: 'MM/dd/yyyy' || '-' }}</td>
                    <td *ngIf="isEdit">
                        <sdk-textbox [value]="projectEdit.technicalReviewCompletionDate" validCharacters="calendar" pattern="MM/DD/YYYY" (changeCallBackEvent)="infoChange({ field: 'technicalReviewCompletionDate', value: $event })"></sdk-textbox>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Methodology</th>
                    <td *ngIf="!isEdit" [title]="parseMethodologyTitle()">
                        {{ parseMethodologyDisplayText() }}
                    </td>
                    <td *ngIf="isEdit">
                        <ccms-methodology-selector
                            [methodologyId]="project.methodology?.id"
                            [legacyMethodology]="project.methodologyProtocol?.methodology"
                            (methodologySelected)="setMethodology($event)"></ccms-methodology-selector>
                        <ng-container *ngIf="project.methodologyProtocol && project.methodologyProtocol.methodology">
                            <small class="longtext" [title]="project.methodologyProtocol.description">
                                {{ project.methodologyProtocol.methodology }} - {{ project.methodologyProtocol.description }}
                            </small>
                        </ng-container>
                    </td>
                </tr>
                    <tr>
                        <th scope="col">Developer</th>
                        <td>{{ project.cvxProjectDeveloper? project.cvxProjectDeveloper.name : '-' }}</td>
                    </tr>

                    <tr>
                        <th scope="col">Verifier</th>
                        <td>{{ project.verifier || '-' }}</td>
                    </tr>
                <tr>
                    <th scope="col">Notes</th>
                    <td *ngIf="!isEdit"><div class="notes">{{ project.technicalReviewNotes || '-' }}</div></td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.technicalReviewNotes" (input)="setNotes('technicalReviewNotes', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Eligibility</th>
                    <td *ngIf="!isEdit">
                        <div class="notes">{{ project.eligibility || '-' }}</div>
                    </td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.eligibility"
                            (input)="setNotes('eligibility', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Carbon</th>
                    <td *ngIf="!isEdit">
                        <div class="notes">{{ project.carbon || '-' }}</div>
                    </td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.carbon"
                            (input)="setNotes('carbon', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Integrity</th>
                    <td *ngIf="!isEdit">
                        <div class="notes">{{ project.integrity || '-' }}</div>
                    </td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.integrity"
                            (input)="setNotes('integrity', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Feasibility</th>
                    <td *ngIf="!isEdit">
                        <div class="notes">{{ project.feasibility || '-' }}</div>
                    </td>
                    <td *ngIf="isEdit"><textarea class="edit" rows="5" [value]="projectEdit.feasibility"
                            (input)="setNotes('feasibility', $event)"></textarea></td>
                </tr>

            </tbody>
        </table>

        <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>
    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" (changeEvent)="infoChange($event)"></tab-info>
    </div>
</div>
