<div class="window">
    <div class="header">
        <div class="title">New Project</div>
        <div title="Close" class="icon material-icons-outlined cursor close" style="float: right;" (click)="close();">close</div>
    </div>
    <div class="content">
      <div class="data">
            <mat-horizontal-stepper #projectWizard>
                <ng-container *ngFor="let step of steps; let i = index">
                    <mat-step>
                        <ng-template matStepLabel>{{step.title}}</ng-template>
                        <ng-template matStepContent>
                            <ng-container [ngComponentOutlet]="step.type" [ndcDynamicInputs]="inputs"
                                [ndcDynamicOutputs]="outputs"></ng-container>
                        </ng-template>
                    </mat-step>
                </ng-container>
            </mat-horizontal-stepper>
        </div>
    </div>
    <div class="footer"> 
      <ng-container *ngIf="alertMessage" class="alert-container">
        <ccms-alert [type]="AT.WARNING" [message]="alertMessage"></ccms-alert>  
      </ng-container>  
      <div class="actions">
            <button (click)="save();"[disabled]="disabled">Save</button>
            <button (click)="projectWizard.previous()" [disabled]="this.projectWizard.selectedIndex === 0">Back</button>
            <button (click)="projectWizard.next()" [disabled]="this.projectWizard.selectedIndex === 5">Next</button>
      </div>
    </div>
</div>