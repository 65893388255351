import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { PagedList } from "~/models/shared/paging/paged-list";
import { HttpService } from "~/services/core/http.service";
import { ProjectPortfolio, ProjectPortfolioSearchOptions } from "./models/project-portfolio.model";

@Injectable({
    providedIn: 'root'
})
export class ProjectPortfolioService {
    constructor(
        private httpService: HttpService,
    ) {
        // Intentionally blank
    }

    public linkProjectPortfolio(projectId: number, portfolioId: string): Observable<void> {
        const requestUri = '/project-portfolios';
        const task = this.httpService.Post(encodeURI(requestUri), {
            projectId: projectId,
            portfolioId: portfolioId
        });
        return from(task);
    }

    public unlinkProjectPortfolio(portfolioId: string): Observable<void> {
        const requestUri = `/project-portfolios/${portfolioId}`;
        const task = this.httpService.Delete(requestUri);
        return from(task);
    }

    public searchAsync(options: ProjectPortfolioSearchOptions): Observable<PagedList<ProjectPortfolio>> {
        let requestUri = '/project-portfolios';
        const queries: string[] = [];
        for (const [k, v] of Object.entries(options)) {
            if (v.replace(/\s/g, "") === '') {
                continue;
            }
            queries.push(`${k}=${v}`);
        }
        if (queries.length > 0) {
            requestUri += `?${queries.join('&')}`;
        }
        const task = this.httpService.Get(encodeURI(requestUri));
        return from(task);
    }
}