import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject, forkJoin, map, mergeMap, of, take } from "rxjs";
import { ProjectService } from "~/services/shared/projects/project.service";
import { WsjfScoreCard } from "~/services/shared/projects/models/wsjf-score-card.model";
import { WsjfScoreCardFormModel } from "./wsjf-score-card-form/wsjf-score-card-form.model";
import { WsjfScoreCardFormComponent, WsjfScoreCardFormGroup } from "./wsjf-score-card-form/wsjf-score-card-form.component";
import { ProjectPortfolioService } from "~/services/shared/projects/project-portfolio.service";
import CcmsServerError from "~/models/shared/errors/ccms-server-error";
import { SaveDataEvent } from "../models/save-data-event.model";
import { AlertType } from "~/models/shared/errors/error-alert-type.enum";
import { KpiService } from "~/services/shared/kpi/kpi.service";
import { WsjfSummary } from "~/services/shared/kpi/models/wsjf-summary.model";
import { UserPermissionsService } from "~/services/shared/user-permissions.service";

@Component({
	selector: 'ccms-wsjf-score-card',
	templateUrl: './wsjf-score-card.component.html',
	styleUrls: ['./wsjf-score-card.component.scss']
})
export class WsjfScoreCardComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() project: any;
	@Input() isEdit: boolean = false;
	@Input() saveData: boolean = false;
	@Output() edit = new EventEmitter<boolean>(false);
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();
	@Output() saveDataEvent: EventEmitter<SaveDataEvent> = new EventEmitter();

	@ViewChild('scoreCardForm')
	scoreCardForm!: WsjfScoreCardFormComponent;

	private _formSubject = new BehaviorSubject<WsjfScoreCardFormGroup>(new WsjfScoreCardFormGroup());
	public form$ = this._formSubject.asObservable();

	private _wsjfScoreCardSubject = new BehaviorSubject<WsjfScoreCard | null>(null);
	private _wsjfScoreCard!: WsjfScoreCard;
	public wsjfScoreCard$ = this._wsjfScoreCardSubject.asObservable();

	public projectEdit: any;
	public isLoading: boolean = false;

	private _wsjfSummarySubject = new BehaviorSubject<WsjfSummary | null>(null);
	wsjfSummary$ = this._wsjfSummarySubject.asObservable();

	private _saveDataChange$ = new Subject<boolean>();

	public error: CcmsServerError | null = null;
	AT = AlertType;
	public businessValueTitle: string = "\nBreakeven Carbon Price($/t CO2e)\nIRR( > 10 %)\nCost Savings vs Carbon Tax\nTotal Volume: \nTotal Offtake Years \nInvestment Type: Opex or Capital\nInvestment Amount\n";
	public timingSensitivityTitle: string = "Dependencies\n\tCounterparty agreement timing & urgency";
	public effortTitle: string = "SME Review of methodology(CTC, CTV engagement, project team size)\nGeography(ex. CVX operations, IPCC ranking?)\nDeal archtype\n Counterparty alignment";
	public opportunityRiskTitle: string = "Due Diligence Results\nDeveloper Risk\nUpside & downside risks\nTechnology/Methodology risks\nEffectiveness\n Additionality\nPermancence\nScalable for growth";
	public canEdit: boolean = false;

	constructor(
		private readonly projectPortfolioService: ProjectPortfolioService,
		private readonly projectService: ProjectService,
		private readonly kpiService: KpiService,
		private readonly userPermissionService: UserPermissionsService
	) {
		// Intentionally blank
	}

	async ngOnInit() {
		this.canEdit = await this.userPermissionService.canUpdateProjects();
	}

	ngAfterViewInit(): void {
		this.wsjfScoreCard$.subscribe(scoreCard => {
			this._wsjfScoreCard = scoreCard as WsjfScoreCard;
			this._formSubject.next(new WsjfScoreCardFormGroup(scoreCard));
			this.kpiService.getWsjfSummary(this.project.id)
				.pipe(
					map(value => {
						value = this.fiterOutCurrentProject(value);
						if (scoreCard) {
							const projectValue =
								(scoreCard?.businessValue ?? 0)
								+ (scoreCard?.timeCriticality ?? 0)
								+ (scoreCard?.riskReductionOpportunityEnablementValue ?? 0);
							const projectEffort = scoreCard?.estimatedImplementationEffort ?? 0;
							value.referenceProject = {
								id: this.project.id,
								name: this.project.name,
								description: this.project.description,
								value: projectValue,
								effort: projectEffort
							};
						}
						return value;
					})
				).subscribe({
					next: (value) => {
						this._wsjfSummarySubject.next(value);
					}
				});
		});
		this.projectService.getWsjfScoreCard(this.project.id).subscribe(scoreCard => {
			this._wsjfScoreCardSubject.next(scoreCard);
		});

		this._saveDataChange$.subscribe((value: boolean) => {
			if (!value) {
				return;
			}
			this.scoreCardForm.onSave();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.saveData && !changes.saveData.firstChange) {
			this._saveDataChange$.next(changes.saveData.currentValue);
		}
		if (this.isEdit) {
			this.isLoading = true;

			this.projectEdit = { ...this.project };

			this.changeEvent.emit(this.projectEdit);

			this.isLoading = false;
		}
	}

	private fiterOutCurrentProject(value: WsjfSummary): WsjfSummary {
		const projectId = this.project.id;

		/// Remove this project from top projects
		if (value.topProjectsByCountry?.length > 0) {
			value.topProjectsByCountry = value.topProjectsByCountry
				.filter(item => item.id != projectId);
		}
		/// Remove this project from top projects
		if (value.topProjectsByProjectType?.length > 0) {
			value.topProjectsByProjectType = value.topProjectsByProjectType
				.filter(item => item.id != projectId);
		}
		return value;
	}

	public initializeForm() {
		this.isEdit = true;
		this.edit.emit(true);
	}

	public onPortfolioIdSelect(portfolioId: string) {
		console.log(portfolioId);
	}
	
	private addProjectPortfolio(): Observable<void> {
		const portfolioService = this.projectPortfolioService;
		const projectId = this.project.id;
		const currentPortfolioProjectId = this.project?.projectPortfolioId;
		if (currentPortfolioProjectId) {
			return portfolioService.unlinkProjectPortfolio(currentPortfolioProjectId)
				.pipe(
					take(1), 
					mergeMap(() => {
						return portfolioService.linkProjectPortfolio(projectId, '');
					}));
		}
		else {
			return portfolioService.linkProjectPortfolio(projectId, '');
		}
	}

	private linkProjectPortfolio(portfolioId: string): Observable<void> {
		if (!portfolioId) {
			throw new Error("Portfolio ID cannot be null or empty.");
		}

		const portfolioService = this.projectPortfolioService;
		const currentPortfolioProjectId = this.project?.projectPortfolioId;
		const projectId = this.project.id;
		if (currentPortfolioProjectId) {
			if (currentPortfolioProjectId == portfolioId) {
				return of(void 0);
			}
			return portfolioService.unlinkProjectPortfolio(currentPortfolioProjectId)
				.pipe(
					take(1), 
					mergeMap(() => {
						const projectId = this.project.id;
						return portfolioService.linkProjectPortfolio(projectId, portfolioId);
					}));
		}
		else {
			return portfolioService.linkProjectPortfolio(projectId, portfolioId);
		}
	}
	
	public saveWsjfScoreCard(data: {
        wsjfScoreCard: WsjfScoreCardFormModel,
        addNewProject: boolean,
        projectPortfolioId: string
    }) {
		let saveWsjfScoreCard: Observable<WsjfScoreCard>;
		const wsjfScoreCard = data.wsjfScoreCard;
		if (this._wsjfScoreCard) {
			saveWsjfScoreCard = this.projectService.updateWsjfScoreCard(this.project.id, wsjfScoreCard);
		}
		else {
			saveWsjfScoreCard = this.projectService.addWsjfScoreCard(this.project.id, wsjfScoreCard)
		}

		let linkProjectPortfolio: Observable<void> = of(void 0);
		const currentPortfolioId = this.project?.projectPortfolioId;
		const newPortfolioId = data.projectPortfolioId;
		if (data.addNewProject) {
			linkProjectPortfolio = this.addProjectPortfolio();
		}
		else if (newPortfolioId) {
			linkProjectPortfolio = this.linkProjectPortfolio(newPortfolioId);
		}
		else if (currentPortfolioId && !newPortfolioId) {
			linkProjectPortfolio = this.projectPortfolioService.unlinkProjectPortfolio(currentPortfolioId);
		}

		const allTasks = forkJoin({
			saveWsjfScoreCard: saveWsjfScoreCard,
			linkProjectPortfolio: linkProjectPortfolio
		});
		saveWsjfScoreCard.subscribe({
			next: value => {
				this._wsjfScoreCardSubject.next(value);
			}
		});

		allTasks.subscribe({
			complete: () => {
				this.saveDataEvent.emit(new SaveDataEvent(false));
			},
			error: error => {
				this.error = new CcmsServerError(error);
				this.saveDataEvent.emit(new SaveDataEvent(false, this.error.errors));
			}
		})
	}

	public infoChange(event: any) {
		this.projectEdit[event.field] = event.value;

		this.changeEvent.emit(this.projectEdit);
	}
}
