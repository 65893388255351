import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProjectService } from '~/services/shared/projects/project.service';
import { InvestmentService } from '~/services/shared/investment.service';
import { CountryService } from '~/services/shared/country.service';

@Component({
    selector: 'tab-info',
    templateUrl: './tab-info.component.html',
    styleUrls: ['./tab-info.component.scss']
})

export class TabInfoComponent {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() status: any;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = false;
    public projectEdit: any;
    public projectTypeList: any = [];
    public projectProductList: any = [];
    public projectReductionTypeList: any = [];
    public projectInvestmentTypeList: any = [];
    public projectCountryList: any[] = [];

    public projectStatus: string = "-";
    public projectStatusHoverText: string = "";
    public globalLocation: any = {
        name: "Global",
        id: -1,
        alpha2Code: "",
        alpha3Code: "",
        regionCode: -1,
        regionName: "Global",
        latLongCoords: null,
        countryRisk: null,
        region: null,
        subRegion: null
    };

    public projectScreening: any;

    constructor(
        private projectService: ProjectService,
        private investmentService: InvestmentService,
        private countryService: CountryService,
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnChanges() {
        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = { ...this.project };

            await this.loadProjectTypeList();
            await this.loadProjectProductsList();
            await this.loadProjectInvestmentTypeList();
            await this.loadProjectCountryList();

            this.isLoading = false;
        }
        this.loadProjectScreening();
        this.setProjectStatus();
    }

    private async loadProjectScreening() {
        if (this.projectScreening == undefined) {            
            await this.projectService.getScreening(this.project.id).then((data: any) => {
                this.projectScreening = data ?? null;
            });
        }
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public setProjectType(event: any) {
        if (event.target.value === "") {
            this.projectEdit.cvxProjectType = {};
        } else {
            let data = event.target.value.split(",");
            let typeNdx: any = this.projectTypeList.findIndex((t: any) => t.name === data[0]);
            let subtypeNdx: any = this.projectTypeList[typeNdx].cvxProjectTypes.findIndex((s: any) => s.id === parseInt(data[1]));

            this.projectEdit.cvxProjectType = {
                "id": this.projectTypeList[typeNdx].cvxProjectTypes[subtypeNdx].id,
                "name": this.projectTypeList[typeNdx].cvxProjectTypes[subtypeNdx].name,
                "cvxProjectScopeName": this.projectTypeList[typeNdx].longName,
                "cvxProjectScope": {
                    "longName": this.projectTypeList[typeNdx].longName,
                    "id": typeNdx + 1,
                    "name": this.projectTypeList[typeNdx].name
                }
            };
        }

        this.changeEvent.emit({ field: 'cvxProjectType', value: this.projectEdit.cvxProjectType });
    }

    public setProduct(event: any) {
        if (event.target.value === "") {
            this.projectEdit.product = {};
        } else {
            this.projectEdit.product = JSON.parse(event.target.value);
        }

        this.changeEvent.emit({ field: 'product', value: this.projectEdit.product });
    }

    public setNumberValue(field: any, event: any) {
        let parsedValue: number | null = null;
            
        if (event?.target?.value !== undefined && event?.target?.value?.length > 0) {
            parsedValue =  parseInt(event.target.value);
        }
    
        if (parsedValue !== undefined) {
            this.projectEdit[field] = parsedValue;
            this.changeEvent.emit({ field: field, value: parsedValue });
        } else {
            console.error('Invalid event or event.target:', event);
        }
    }
    
    public setStartDate(event: any) {
        this.projectEdit.executionStartDate = event;

        this.changeEvent.emit({ field: 'executionStartDate', value: this.projectEdit.executionStartDate });
    }

    public setNdaExecutionDate(event: any) {
        this.projectEdit.ndaExecutionDate = event;

        this.changeEvent.emit({ field: 'ndaExecutionDate', value: this.projectEdit.ndaExecutionDate });
    }

    public setCustomer(event: any) {
        if (event.target.value === "") {
            this.projectEdit.product = {};
        } else {
            this.projectEdit.product.name = JSON.parse(event.target.value);
        }

        this.changeEvent.emit({ field: 'product', value: this.projectEdit.product });
    }

    public setReductionType(event: any) {
        this.projectEdit.isRemoval = false;
        this.projectEdit.isAvoidance = false;

        if (event.target.value !== "") {
            switch (event.target.value) {
                case "Removal":
                    this.projectEdit.isRemoval = true;
                    break;

                case "Avoidance":
                    this.projectEdit.isAvoidance = true;
                    break;

                case "Both":
                    this.projectEdit.isRemoval = true;
                    this.projectEdit.isAvoidance = true;
                    break;
            }
        }

        this.changeEvent.emit({ field: 'isRemoval', value: this.projectEdit.isRemoval });
        this.changeEvent.emit({ field: 'isAvoidance', value: this.projectEdit.isAvoidance });
    }

    public setProjectEpicId(event: string) {
        this.projectEdit.projectPortfolioId = event;
        this.changeEvent.emit({ field: 'projectPortfolioId', value: event });
    }

    public setInvestmentType(event: any) {
        // Assuming event is of type Event & { target: HTMLInputElement }  
        if (event.target.value === "") {
            // Assign a new object to investmentType if the value is an empty string  
            this.projectEdit.investmentType = {};
        } else {
            // Parse the value and assign it to the name property of investmentType  
            // Make sure to check if investmentType is not null before assigning  
            if (this.projectEdit.investmentType === null) {
                // Initialize investmentType as an object if it's null  
                this.projectEdit.investmentType = {};
            }
            this.projectEdit.investmentType.name = JSON.parse(event.target.value);
        }  


        this.changeEvent.emit({ field: 'investmentType', value: this.projectEdit.investmentType.name });
    }

    public setCountry(event: any) {

        if (event.target.value !== "") {
            
            const selected = JSON.parse(event.target.value);
            if (selected.id == this.globalLocation.id) {
                this.projectEdit.isGlobalLocation = true;
                this.projectEdit.location.addressCountry = null;
            }
            else {
                this.projectEdit.country = selected;
                this.projectEdit.location = this.projectEdit.location || {};
                this.projectEdit.location.addressCountry = selected;
                this.projectEdit.isGlobalLocation = null;  
            }
            this.changeEvent.emit({ field: 'isGlobalLocation', value: this.projectEdit.isGlobalLocation });
            this.changeEvent.emit({ field: 'location', value: this.projectEdit.location });
        }

      
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    public isRequired() {
        const LEAD_STATUS_ID = 14;
        return this.isEdit 
            && this.status?.id != LEAD_STATUS_ID;
    }

    private setProjectStatus() {
        this.projectStatus = this.status?.name ?? this.project.status?.name ?? "";

        if (this.project.didNotPursueReason?.name) {
            this.projectStatus += ` - ${this.project.didNotPursueReason.name}`;
        }

        if (this.project.didNotPursueReasonOther) {
            this.projectStatus += ` - ${this.project.didNotPursueReasonOther}`;
        } else {
            this.projectStatus += this.project.projectStatusNotes ? ` - ${this.project.projectStatusNotes}` : "";
        }

        this.projectStatusHoverText = this.projectStatus;
    }

    private async loadProjectTypeList(): Promise<void> {
        this.projectTypeList = [];

        try {
            await this.projectService.getTypes().then((data: any) => {
                this.projectTypeList = data;
            });
        } catch (error: any) {
            this.projectTypeList = [];
        }
    }

    private async loadProjectProductsList(): Promise<void> {
        this.projectProductList = [];

        try {
            await this.projectService.getProducts().then((data: any) => {
                this.projectProductList = data;
            });
        } catch (error: any) {
            this.projectProductList = [];
        }
    }

    private async loadProjectInvestmentTypeList(): Promise<void> {
        this.projectInvestmentTypeList = [];

        try {
            await this.investmentService.getTypes().then((data: any) => {
                this.projectInvestmentTypeList = data;
            });
        } catch (error: any) {
            this.projectInvestmentTypeList = [];
        }
    }

    private async loadProjectCountryList() {
        this.projectCountryList = [];

        try {
            await this.countryService.getCountries().then((data: any) => {
                this.projectCountryList = data;
                if (this.projectCountryList.find(element => element.id == this.globalLocation.id) == undefined) {
                    this.projectCountryList.unshift(this.globalLocation);    
                }
                
            });
        } catch (error: any) {
            this.projectCountryList = [];
        }
    }
}
