import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import CcmsServerError from "~/models/shared/errors/ccms-server-error";
import { AlertType } from "~/models/shared/errors/error-alert-type.enum";
import { ProjectPriorityScoreCard } from "~/services/shared/projects/models/project-priority-score-card";
import { ProjectService } from "~/services/shared/projects/project.service";
import { ProjectPriorityScoreCardFormComponent } from "./components/project-priority-score-card-form/project-priority-score-card-form.component";
import { SaveDataEvent } from "../models/save-data-event.model";

@Component({
    selector: 'ccms-priority-score-card',
    templateUrl: './priority-score-card.component.html',
    styleUrls: ['./priority-score-card.component.scss']
})
export class ProjectPriorityScoreCardComponent implements OnChanges {
	@Input() project: any;
	@Input() isEdit: boolean = false;
	@Input() saveData: boolean = false;
	@Output() changeEvent: EventEmitter<any> = new EventEmitter();
	@Output() saveDataEvent: EventEmitter<SaveDataEvent> = new EventEmitter();

    @ViewChild('scoreCard') scoreCard!: ProjectPriorityScoreCardFormComponent;

    public AT = AlertType;
	public error: CcmsServerError | null = null;
    public priorityScoreCard: ProjectPriorityScoreCard | null = null;

    constructor(
        private readonly projectService: ProjectService
    ) {
        // Intentionally blank
    }

    public async ngOnChanges(changes: SimpleChanges) {
		if (changes.saveData?.currentValue
            && !changes.saveData.firstChange) {
            await this.onFormSavedAsync();
		}
        if (changes.project) {
            this.onProjectChanged();
        }
    }

    public infoChange(event: any) {
        const project = this.project;
        project[event.field] = event.value;
		this.changeEvent.emit(project);
    }

    private async onFormSavedAsync() {
        const projectId = this.project.id;
        const data = this.scoreCard.getFormData();
        const subscription = this.projectService
            .setPriorityScoreCard(projectId, data)
            .subscribe({
                next: (value) => {
                    subscription.unsubscribe();
                    this.priorityScoreCard = value;
                    this.saveDataEvent.emit(new SaveDataEvent(false));
                },
                error: (error) => {
                    subscription.unsubscribe();
                    this.error = new CcmsServerError(error);
                    this.saveDataEvent.emit(new SaveDataEvent(false, this.error.errors));
                }
            });
    }

    private onProjectChanged() {
        const projectId = this.project.id;
        const subscription = this.projectService
            .getPriorityScoreCard(projectId)
            .subscribe(scoreCard => {
                console.log(scoreCard);
                this.priorityScoreCard = scoreCard;
                subscription.unsubscribe();
            });
    }
}