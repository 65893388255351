import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject } from 'rxjs';
import { Message, MessageThread } from '~/services/shared/chat/chat-service.model';
import { ChatService } from '~/services/shared/chat/chat.service';
import md from 'markdown-it';

@Component({
	selector: 'ccms-chat-box',
	templateUrl: './chat-box.component.html',
	styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements AfterViewInit {
	@ViewChild('chatHistory') chatHistory!: ElementRef;

	private readonly markdown;

	private chartNumber:number = 1;

	private _currentContext!: string;
	private _isOpenedSubject = new BehaviorSubject<boolean>(false);
	isOpened$ = this._isOpenedSubject.asObservable();

	private _isBusySubject = new BehaviorSubject<boolean>(false);
	isBusy$ = this._isBusySubject.asObservable();

	private messageThread = {
		context: 'CvxProject',
		messages: []
	} as MessageThread;

	private _messageHistorySubject = new BehaviorSubject<Message[]>([]);
	messageHistory$ = this._messageHistorySubject.asObservable();

	userMessage: string = '';

	constructor(
		private readonly authService: MsalService,
		private readonly chatService: ChatService,
		private readonly router: Router
	) {
		this.markdown = md();
	}
	ngAfterViewInit(): void {
		this.router.events.subscribe(route => {
			if (!(route instanceof NavigationEnd)) {
				return;
			}
			if (this._currentContext !== this.resolveChatContext()) {
				this.intializeMessageThread();
			}
		});
	}

	private intializeMessageThread() {
		// Intentionally blank
		this.messageThread.messages = [];
		this.messageThread.messages.push({
			timestamp: new Date(),
			datasource: '',
			role: 'assistant',
			content: `Hi ${this.getUserName()}, how can I help you today?`,
			type: 'text',
			canvas: ""
		});
		this._messageHistorySubject.next(this.messageThread.messages);
		this._currentContext = this.resolveChatContext();
	}
	private getUserName() {
		const name = this.authService.instance.getActiveAccount()?.name;
		if (!name) {
			return '';
		}
		const nameParts: string[] = name.split(", ");

		if (!nameParts) {
			return '';
		}

		const firstName: string = nameParts[1].split(" (")[0].split(" [")[0];
		const lastName: string = nameParts[0];

		return `${firstName} ${lastName}`;
	}

	private resolveChatContext() {
		const currentRoute = this.router.url;
		if (currentRoute.startsWith('/metaregistry')) {
			return 'MetaRegistry'
		}

		// Default context
		return 'CvxProject';
	}

	toggleChatBox(isOpened: boolean) {
		this._isOpenedSubject.next(isOpened);
	}

	submitQuestion() {
		if (!this.userMessage) {
			return;
		}
		this._isBusySubject.next(true);

		this.messageThread.context = this.resolveChatContext();
		this.messageThread.messages.push({
			timestamp: new Date(),
			datasource: '',
			role: 'user',
			content: this.userMessage,
			type: 'text',
			canvas: ""
		});
		this.scrollToBottom();
		this.userMessage = '';

		this.chatService.submitChatMessage(this.messageThread).subscribe(response => {
			response.messages.forEach(response => {
				const message = {
					timestamp: new Date(response.timestamp),
					datasource: '',
					role: response.role,
					content: response.content,
					type: 'text',
					canvas: ""
				};
				
				if (response.role === 'assistant') {
					if (response.content.includes("SCRIPT:") && response.content.includes("END CODE")) {
						message.type = 'chart';

						let start = response.content.indexOf("SCRIPT:") + ("SCRIPT:".length);
						let end = response.content.indexOf("END CODE");

						let chartJsCode = response.content.substring(start, end);
						chartJsCode = chartJsCode.replaceAll("```javascript", "")
												 .replaceAll("```", "")
												 .replaceAll("<p>", "")
												 .replaceAll("</p>", "")
												 .replaceAll("<pre>", "")
												 .replaceAll("</pre>", "")
												 .replaceAll('<code class="language-javascript">', "")
												 .replaceAll("</code>", "");

						let canvasIdBegin = chartJsCode.indexOf("getElementById('") + "getElementById('".length;
						let canvasIdEnd = chartJsCode.indexOf("').getContext");

						let canvasId = chartJsCode.substring(canvasIdBegin, canvasIdEnd);
						message.canvas = canvasId;

						const scriptTag = document.createElement('script');
						scriptTag.id = 'chart_js' + this.chartNumber.toString();
    					scriptTag.type = 'text/javascript';
						document.body.appendChild(scriptTag);

						let script = document.getElementById(scriptTag.id);
						script!.innerHTML = "function showChart() { " + [chartJsCode] + " } setTimeout(function() { showChart(); }, 1000);";
						this.chartNumber += 1;
					}
				}
				message.content = this.encodeMarkdown(message.content);
				message.datasource = this.messageThread.context;

				this.messageThread.messages.push(message);
			});
			this.scrollToBottom();
			this._isBusySubject.next(false);
		});
	}
	private encodeMarkdown(content: string): string {
		return this.markdown.render(content);
	}

	private scrollToBottom() {
		setTimeout(() => {
			this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
		}, 100);
	}



}
