import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SDKDataGridColumn, SDKDataGridDataset, SDKDataGridOptions, SDKDatagridComponent } from 'sdk-datagrid';
import { AgreementService } from '~/services/shared/agreements/agreements.service';
import { IAgreementPaperType } from '~/services/shared/agreements/models/agreement-paper-type';
import { IAgreementType } from '~/services/shared/agreements/models/agreement-type';
import { IProjectAgreement } from '~/services/shared/agreements/models/agreement.model';
import { FormatterService } from '~/services/shared/formatter.service';

@Component({
    selector: 'project-detail-agreements',
    templateUrl: './project-detail-agreements.component.html',
    styleUrls: ['./project-detail-agreements.component.scss']
})

export class ProjectDetailAgreementsComponent {

    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('paper') paperTemplate!: TemplateRef<any>;
    @ViewChild('type') typeTemplate!: TemplateRef<any>;
    @ViewChild('detail') detailTemplate!: TemplateRef<any>;
    @ViewChild('name') nameTemplate!: TemplateRef<any>;
    @ViewChild('duration') durationTemplate!: TemplateRef<any>;
    @ViewChild('destructionRequested') destructionRequestedTemplate!: TemplateRef<any>;
    @ViewChild('destructionRequestedDate') destructionRequestedDateTemplate!: TemplateRef<any>;
    
    @ViewChild('dateExpires') dateExpiresTemplate!: TemplateRef<any>;
    @ViewChild('dateExecuted') dateExecutedTemplate!: TemplateRef<any>;
    @ViewChild('actionRight') actionRight!: TemplateRef<any>;
    @ViewChild('grid') grid!: SDKDatagridComponent;
    

    
    public isLoading: boolean = true;
    public data: IProjectAgreement[] = [];
    
    
    public agreementTypes: IAgreementType[] = [];
    public agreementPapers: IAgreementPaperType[] = [];
    public readonly today: Date = new Date()
    public projectEdit: any;
    public columns: SDKDataGridColumn[] = [
        { Name: "name", DisplayName: "Name", required: true, isVisible: true, allowEdit: true, dataTemplate: () => this.nameTemplate},
        { Name: "type", DisplayName: "Type", required: true, isVisible: true, allowEdit: true,  dataTemplate:()=> this.typeTemplate },
        { Name: "paper", DisplayName: "Paper", isVisible: true, allowEdit: true, dataTemplate:() =>  this.paperTemplate},
        { Name: "duration", DisplayName: "Duration", width: "60px", isVisible: true, allowEdit: true, dataTemplate: () => this.durationTemplate},
        { Name: "destructionRequested", DisplayName: "Destruction Req'd", isVisible: true, allowEdit: true, dataTemplate: () => this.destructionRequestedTemplate},
        { Name: "destructionRequestedDate", DisplayName: "Req'd Date", isVisible: true, allowEdit: true, formatter: (value: any) => this.dateFormatter(value), dataTemplate: () => this.destructionRequestedDateTemplate},
        { Name: "id", isVisible: false, allowEdit: false },
        { Name: "dateExpires", DisplayName: "Exp Date", isVisible: true, allowEdit: true, formatter: (value: any) => this.dateFormatter(value), dataTemplate: () => this.dateExpiresTemplate},
        { Name: "dateExecuted", DisplayName: "Executed Date", isVisible: true, allowEdit: true, formatter: (value: any) => this.dateFormatter(value), dataTemplate: () => this.dateExecutedTemplate },
        { Name: "createdOn", DisplayName: "Created", isVisible: true, allowEdit: false, formatter: (value: any) => this.dateFormatter(value) },
        { Name: "modifiedOn", DisplayName: "Modified", isVisible: true, allowEdit: false, formatter: (value: any) => this.dateFormatter(value) },
        { Name: 'Action', DisplayName: 'Action', actionSide: "right", actionTemplate: () => this.actionRight },
    ];
    public readonly gridOptions: SDKDataGridOptions = {
        columnSettings: false,
        filtering: false,
        sorting: false,
        formulas: false,
        charts: false,
        export: false,
        expandableRows: true,
        selectableRows: false,
        autoClosePanel: false
    };
    public readonly datasets: SDKDataGridDataset[] = [
        {
            Title: "Project Agreements",
            DbName: "agreements/{projectId}",
        }
    ];
    public hasRecords: boolean = false;
    private changeArray: IProjectAgreement[] = [];

    constructor(private agreementService: AgreementService,
        private formatterService: FormatterService,) {
       
    }

	//******************************************************************************
	//  Page Life-cycle Methods
	//******************************************************************************
    public ngOnInit () {
        if (this.project?.sustainableDevelopmentGoals) {
            this.project.sustainableDevelopmentGoals.sort((a: any, b: any) => (a.id < b.id ? -1 : 1));
            
            
        }
        this.agreementService.getAgreementTypes()
            .subscribe(types => {
                this.agreementTypes = types;
             });
        this.agreementService.getPaperTypes()
            .subscribe(papers => { 
                this.agreementPapers = papers;
            });
        
    }

    async ngOnChanges() {
        if (this.saveData) {
            //persist any changes here
            this.onSave(this.changeArray);
            this.changeArray = [];
            this.saveDataEvent.emit(false);
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = { ...this.project };

            this.isLoading = false;
        }
        if (!this.saveData && !this.isEdit) {
            this.loadData();
        }
    }

    public loadData(event: any = null) {
      this.agreementService.getAgreements(this.project.id)
          .subscribe(agreements => {
              this.isLoading = false;
              agreements.sort((a, b) => b.id - a.id);
              this.data = agreements;
              this.hasRecords = this.data.length > 0;
          });
    }
  
    private initializeModel(): any{
        return {
            projectId: this.project.id,
            name: "",
            type: {
                id: 0,
                name: "",
                description: "",
            },
            counterParty: "",
            paper: {
                id: 0,
                name: "",
                description: "",
            },
            notes: "",
            restrictions: "",
            duration: null,
            obligations: "",
            source: "",
            id: 0,
            createdOn: null,
            modifiedOn: null,
            dateExpires: null,
            dateExecuted: null
        };
    }
 
    public onSave(agreements: IProjectAgreement[]): void{
        if (agreements?.length == 0) {
            return;
        }
        this.isLoading = true;
        if (agreements.every(element => element.id > 0)) {
            this.agreementService.saveAgreements(this.project.id, agreements)
                .subscribe(() => { this.isLoading = false; });        
        }
        else {
            this.agreementService.createAgreements(this.project.id, agreements)
                .subscribe(() => {
                    this.loadData();
                });
        }
    }
    public onTypeChange($event: any, model: IProjectAgreement) {
        const typeModel = JSON.parse($event.target.value);
        console.log(typeModel);
        
        if (model && typeModel) {
            model.type = typeModel;
        }
        this.onDataChange(model);
    }
    public onPaperChange($event: any, model: IProjectAgreement) {
        const paperModel = JSON.parse($event.target.value);
        
        if (model && paperModel) {
            model.paper = paperModel;
            this.onDataChange(model);
        }
    }

    public onDelete(id: number) {
        if (confirm("Are you sure you want to delete this item?")) {
            this.agreementService.deleteAgreement(id).subscribe(() => { 
                this.data = [... this.data.filter(element => element.id != id)];
            });
        }
    }
    public async onAdd() {
        this.data.unshift(this.initializeModel());
        this.grid?.ngOnChanges(this);
        this.grid?.reloadData(false); 
        this.grid?.toggleExpanded(this.data[0]);
        this.hasRecords = true;
        
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;

        this.changeEvent.emit(this.projectEdit);
    }

    private dateFormatter(value:any): string{
        
        const date = new Date(value);
        const dateString = "0001-01-01T00:00:00+00:00";
        const invalid = new Date(dateString);  


        if (date.getFullYear() === invalid.getFullYear()) {
            return "";
        }
        return this.formatterService.formatDate(value, "MM/dd/yyyy");
    }
    public onDataChange(model: any): void {
        //AHN: edge case where multiple new agreements are being added at same time. Might work for editing agreements...
        if (this.changeArray.findIndex(element => element == model) == -1) {
            this.changeArray.push(model);
            return;
        }
        if (this.changeArray.findIndex(element => element.id == model.id) == -1) {
            this.changeArray.push(model);
        }
    }

    public setDate(event: any, rowItem: any, property: string) {
        if (event === "") {
            return;
        } else {
            rowItem[property] = new Date(event);
            this.onDataChange(rowItem);
        }
    }
}
