import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserInfo } from '~/models/shared/users/user-info';
import { UserService } from '~/services/shared/users/user-service';

@Component({
    selector: 'user-selection',
    templateUrl: './user-selection.component.html',
    styleUrls: ['./user-selection.component.scss']
})

export class UserSelectionComponent implements OnInit {
    @Input() entity: string = '';
    @Input() field: string = '';
    @Input() selectedUserName: string = '';

    @Output() userSelectedEvent: EventEmitter<UserInfo> = new EventEmitter();

    public userSelectionList: UserInfo[] = [];

    public isLoading: boolean = false;

    constructor(
        private userService: UserService) {
    }

    ngOnInit(): void {
        this.loadUserSelectionList();
    }

    public userSelectionChange(event: any) {
        const userId = event.target.value;
        const user: UserInfo | undefined = this.userSelectionList.find(u => u.id === userId);

        this.userSelectedEvent.emit(user);
    }

    private loadUserSelectionList() {
        this.isLoading = true;
        this.userService.GetUserSelections(this.entity, this.field).subscribe({
            next: (users) => {
                this.userSelectionList = users;
                this.isLoading = false;
            },
            error: (error) => {
                console.error("Error getting user selection list: " + error.message);
                this.isLoading = false;
            }
        });
    }



}