<div class="content">
    <div class="data">
        <div class="header">Screening</div>
        <div *ngIf="!isLoading" class="detail">
            <div *ngIf="!isEdit && !screeningData" class="nodata">No Screening data available for this project.</div>
            <table *ngIf="isEdit || (!isEdit && screeningData)" class="table-x">
                <caption style="display: none;">Screening</caption>
                <tbody>
                    <tr>
                        <th scope="col" rowspan="2" [ngClass]="{ 'required label': isEdit }">Strategic Fit / Customer Value</th>
                        <td *ngIf="!isEdit">{{ screeningData.isAccretiveToPorfolio !== null ? (screeningData.isAccretiveToPorfolio ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="isAccretiveToPorfolio" [value]="screeningEdit?.isAccretiveToPorfolio ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                    <tr>
                        <td *ngIf="!isEdit"><div class="notes">{{ screeningData.isAccretiveToPorfolioNotes || '-' }}</div></td>
                        <td *ngIf="isEdit"><textarea class="edit" rows="3" [value]="screeningEdit?.isAccretiveToPorfolioNotes" (input)="setNotes('isAccretiveToPorfolioNotes', $event)"></textarea></td>
                    </tr>
                    <tr>
                        <th scope="col" rowspan="2" [ngClass]="{ 'required label': isEdit }">Scale Potential</th>
                        <td *ngIf="!isEdit">{{ screeningData.isScalable !== null ? (screeningData.isScalable ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="isScalable" [value]="screeningEdit?.isScalable ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                    <tr>
                        <td *ngIf="!isEdit"><div class="notes">{{ screeningData.isScalableNotes || '-' }}</div></td>
                        <td *ngIf="isEdit"><textarea class="edit" rows="3" [value]="screeningEdit?.isScalableNotes" (input)="setNotes('isScalableNotes', $event)"></textarea></td>
                    </tr>
                    <tr>
                        <th scope="col" rowspan="2" [ngClass]="{ 'required label': isEdit }">Actionable</th>
                        <td *ngIf="!isEdit">{{ screeningData.isActionable !== null ? (screeningData.isActionable ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="isActionable" [value]="screeningEdit?.isActionable ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                    <tr>
                        <td *ngIf="!isEdit"><div class="notes">{{ screeningData.isActionableNotes || '-' }}</div></td>
                        <td *ngIf="isEdit"><textarea class="edit" rows="3" [value]="screeningEdit?.isActionableNotes" (input)="setNotes('isActionableNotes', $event)"></textarea></td>
                    </tr>
                    <tr>
                        <th scope="col" rowspan="2" [ngClass]="{ 'required label': isEdit }">Due Diligence</th>
                        <td *ngIf="!isEdit">{{ screeningData.reviewPassed !== null ? (screeningData.reviewPassed ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="reviewPassed" [value]="screeningEdit?.reviewPassed ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                    <tr>
                        <td *ngIf="!isEdit"><div class="notes">{{ screeningData.isReviewPassedNotes || '-' }}</div></td>
                        <td *ngIf="isEdit"><textarea class="edit" rows="3" [value]="screeningEdit?.isReviewPassedNotes" (input)="setNotes('isReviewPassedNotes', $event)"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header">Impact</div>
        <div *ngIf="!isLoading" class="detail">
            <div *ngIf="!isEdit && !screeningData" class="nodata">No Impact data available for this project.</div>
            <table *ngIf="isEdit || (!isEdit && screeningData)" class="table-x">
                <caption style="display: none;">Impact</caption>
                <tbody>
                    <tr>
                        <th scope="col">Total Carbon Credits Forecast</th>
                        <td *ngIf="!isEdit">{{ (screeningData.totalCreditsForecast) ? (screeningData.totalCreditsForecast | number) + ' MMTCO2e' : '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox style="display: inline-flex" [value]="screeningEdit?.totalCreditsForecast ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'totalCreditsForecast', value: $event })"></sdk-textbox> MMTCO2e</td>
                    </tr>
                    <tr>
                        <th scope="col">Removal / Avoidance</th>
                        <td *ngIf="!isEdit">{{ screeningData.reductionType || '-' }}</td>
                        <td *ngIf="isEdit">
                            <select (change)="setReductionType($event)">
                                <option value="" [selected]="!screeningEdit?.reductionType">...</option>
                                <option value="Avoidance" [selected]="screeningEdit?.reductionType === 'Avoidance'">Avoidance</option>
                                <option value="Removal" [selected]="screeningEdit?.reductionType === 'Removal'">Removal</option>
                                <option value="Both" [selected]="screeningEdit?.reductionType === 'Both'">Both</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Compliance / Market</th>
                        <td *ngIf="!isEdit">{{ screeningData.registryType || '-' }}</td>
                        <td *ngIf="isEdit">
                            <select (change)="setRegistryType($event)">
                                <option value="" [selected]="!screeningEdit?.registryType">...</option>
                                <option value="Compliance" [selected]="screeningEdit?.registryType === 'Compliance'">Compliance</option>
                                <option value="Market" [selected]="screeningEdit?.registryType === 'Market'">Voluntary (Market)</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Co-benefits & SDG</th>
                        <td *ngIf="!isEdit">{{ screeningData.hasCobenefits !== null ? (screeningData.hasCobenefits ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="hasCobenefits" [value]="screeningEdit?.hasCobenefits ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                    <tr>
                        <th scope="col">Fungible</th>
                        <td *ngIf="!isEdit">{{ screeningData.isFungible !== null ? (screeningData.isFungible ? 'Yes' : 'No') : '-' }}</td>
                        <td *ngIf="isEdit"><radio-button name="isFungible" [value]="screeningEdit?.isFungible ?? null" (changeEvent)="screeningChange($event)"></radio-button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header">Return Potential</div>
        <div *ngIf="!isLoading" class="detail">
            <div *ngIf="!isEdit && !screeningData" class="nodata">No Return Potential data available for this project.</div>
            <table *ngIf="isEdit || (!isEdit && screeningData)" class="table-x">
                <caption style="display: none;">Impact</caption>
                <tbody>
                    <tr>
                        <th scope="col">NPV / IRR</th>
                        <td *ngIf="!isEdit">{{ getReturnMetric(screeningData.returnsMetric) || '-' }}</td>
                        <td *ngIf="isEdit">
                            <select (change)="setReturnsMetric($event)">
                                <option value="" [selected]="!screeningEdit?.returnsMetric || screeningEdit?.returnsMetric === ''">...</option>
                
                                <ng-container *ngFor="let metric of returnMetricList">
                                    <option [value]="metric | json" [selected]="screeningEdit?.returnsMetric?.id === metric.id">{{ metric.name }}</option>
                                </ng-container>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="!isEdit && screeningData?.returnsMetric?.id === 1 || (isEdit && screeningEdit?.returnsMetric?.id === 1)">
                        <th scope="col">Expected IRR</th>
                        <td *ngIf="!isEdit">
                            <div><span class="label">Mid:</span>{{ (screeningData.estimatedMidInternalRateOfReturn) ? screeningData.estimatedMidInternalRateOfReturn + '%' : '-' }}</div>
                            <div><span class="label">Low:</span>{{ (screeningData.estimatedMinimumInternalRateOfReturn) ? screeningData.estimatedMinimumInternalRateOfReturn + '%' : '-' }}</div>
                            <div><span class="label">High:</span>{{ (screeningData.estimatedMaximumInternalRateOfReturn) ? screeningData.estimatedMaximumInternalRateOfReturn + '%' : '-' }}</div>
                        </td>
                        <td *ngIf="isEdit">
                            <div>
                                <div class="label" [ngClass]="{ 'required label': isEdit }" style="display: inline-flex; width: 50px;">Mid:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.estimatedMidInternalRateOfReturn ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'estimatedMidInternalRateOfReturn', value: $event })"></sdk-textbox> %
                            </div>
                            <div>
                                <div class="label" style="display: inline-flex; width: 50px;">Low:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.estimatedMinimumInternalRateOfReturn ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'estimatedMinimumInternalRateOfReturn', value: $event })"></sdk-textbox> %
                            </div>
                            <div>
                                <div class="label" style="display: inline-flex; width: 50px;">High:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.estimatedMaximumInternalRateOfReturn ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'estimatedMaximumInternalRateOfReturn', value: $event })"></sdk-textbox> %
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isEdit && screeningData?.returnsMetric?.id === 2 || (isEdit && screeningEdit?.returnsMetric?.id === 2)">
                        <th scope="col">Net NPV</th>
                        <td *ngIf="!isEdit">
                            <div><span class="label">Mid:</span>{{ (screeningData.midNetPresentValuePerTonne) ? screeningData.midNetPresentValuePerTonne + '%' : '-' }}</div>
                            <div><span class="label">Low:</span>{{ (screeningData.minNetPresentValuePerTonne) ? screeningData.minNetPresentValuePerTonne + '%' : '-' }}</div>
                            <div><span class="label">High:</span>{{ (screeningData.maxNetPresentValuePerTonne) ? screeningData.maxNetPresentValuePerTonne + '%' : '-' }}</div>
                        </td>
                        <td *ngIf="isEdit">
                            <div>
                                <div class="label" [ngClass]="{ 'required label': isEdit }" style="display: inline-flex; width: 50px;">Mid:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.midNetPresentValuePerTonne ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'midNetPresentValuePerTonne', value: $event })"></sdk-textbox> %
                            </div>
                            <div>
                                <div class="label" style="display: inline-flex; width: 50px;">Low:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.minNetPresentValuePerTonne ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'minNetPresentValuePerTonne', value: $event })"></sdk-textbox> %
                            </div>
                            <div>
                                <div class="label" style="display: inline-flex; width: 50px;">High:</div>
                                <sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.maxNetPresentValuePerTonne ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'maxNetPresentValuePerTonne', value: $event })"></sdk-textbox> %
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Abatement Cost</th>
                        <td *ngIf="!isEdit">{{ (screeningData.estimatedAbatementCost) ? screeningData.estimatedAbatementCost + ' $/tonnes' : '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="screeningEdit?.estimatedAbatementCost ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'estimatedAbatementCost', value: $event })"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Price Deck</th>
                        <td *ngIf="!isEdit">{{ getPriceDeck(screeningData.priceDeckId) || '-' }}</td>
                        <td *ngIf="isEdit">
                            <select (change)="setPriceDeck($event)">
                                <option value="" [selected]="!screeningEdit?.priceDeck || screeningEdit?.priceDeck === ''">...</option>
                
                                <ng-container *ngFor="let deck of priceDeckList">
                                    <option [value]="deck | json" [selected]="screeningEdit?.priceDeckId === deck.id">{{ deck.name }}</option>
                                </ng-container>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col">Probability of Occurrence</th>
                        <td *ngIf="!isEdit">{{ (screeningData.confidenceEstimate) ? screeningData.confidenceEstimate + '%' : '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox style="display: inline-flex" width="50px" [value]="screeningEdit?.confidenceEstimate ?? null" validCharacters="decimal" (changeCallBackEvent)="screeningChange({ field: 'confidenceEstimate', value: $event })"></sdk-textbox> %</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" (changeEvent)="infoChange($event)"></tab-info>
    </div>
</div>